.item {
  display:block;
  margin: 15px 0;
  font-weight: bold;
  text-transform: uppercase;

}

.linkWrap {
  position: absolute;
  bottom: 40px;
  left:0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.link {
  font-weight: bold;
  text-transform: uppercase;
}
