import React from "react"
import Cx from 'classnames'
import styles from './styles.scss'

import LogoBlackwingSignup from '../LogoBlackwingSignup'
import ThankYou from '../ThankYou'
import social from '../../assets/img/social.svg'

import { isFirefoxMobile } from '~/app/helpers/helpers'

import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"


class MailchimpSimpleForm extends React.Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.input &&
    this.input.value.indexOf("@") > -1 &&
    this.props.onSubmitted({
      EMAIL: this.input.value
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.submit
    }
  }

  // TODO — use state to handle this…
  handleChange(e) {
    if (e.target.value) {
      const submitButton = document.querySelector(`.${styles.wrap} button`)
      submitButton.classList.add(styles.buttonShow)
    }
  }


  componentDidMount() {
    const controller = new ScrollMagic.Controller()
    const scene = new ScrollMagic.Scene({triggerElement: this.line, offset: 0 - window.innerHeight/2})
      .setClassToggle(this.line, Cx(styles.lineShow))
      .addTo(controller)

  }

  render() {
    return (
      <div className={Cx(styles.wrap)} >
        <div className={Cx(styles.inner, {[styles.success]: this.props.status == 'success'})}>
          <div className={Cx(styles.notice)}><b>Sign up</b> to stay informed</div>

          { isFirefoxMobile() && // https://github.com/revolunet/react-mailchimp-subscribe/issues/25
            <a href="http://eepurl.com/gewNYz" target="_blank">
              <img className={Cx(styles.externalLinkButton)} src={social} />
            </a>
          }

          { !isFirefoxMobile() &&
          <div className={Cx(styles.form)}>
            <input
              ref={node => (this.input = node)}
              type="email"
              placeholder="EMAIL"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
            />
            <div className={Cx(styles.line)} ref={node => (this.line = node)} />
            <button onClick={this.handleSubmit}>SUBMIT</button>
          </div>
          }
          
          { this.props.status == 'success' &&
          <div>
            <LogoBlackwingSignup />
            <ThankYou />
          </div>
          }
        </div>
      </div>
    )
  }
}

export default MailchimpSimpleForm
