import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import { isDesktop, isMobile, isMobileOS } from '~/app/helpers/helpers'

import { TweenMax, TimelineMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"
import smoothscroll from 'smoothscroll-polyfill'


class Logo extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      isClickable: false // when the logo functions as the button to show mobile nav
    }
  }

  componentDidMount() {

    // load all the lines, convert Nodelist to array
    let els = [].slice.call(
      this._el.querySelectorAll(`.${styles.logo} g > *`)
    )
    const duration = 10.0
    const delayVal = 0.2
    let tl = new TimelineMax()
    let breathingEffectLoop = new TimelineMax({delay: .75 + duration, yoyo: true, repeat: -1, repeatDelay: 0})

    let top = "50vh"

    if (isMobileOS())
      top = "calc(50vh - 35px)"

    tl.to(
      this._el,
      0,
      { top, transformY: '-50%' }
    )
    tl.fromTo(
      this._el,
      1.5,
      { opacity: 0 },
      { opacity: 1},
      .4
    )
    tl.fromTo(
      els.slice(0,-1), // all lines except for the "7" icon
      8,
      { attr: { "stroke-width": .5 } },
      { delay: delayVal, attr: { "stroke-width": 5}, ease: Power1.easeOut },
      0
    )
    tl.to(els[0], duration, {delay: delayVal, attr:{cx:200}}, 0)
      .to(els[1], duration, {delay: delayVal, attr:{cx:360}}, 0)
      .to([els[3], els[4]], duration, {delay: delayVal, attr:{x1:200, x2: 360}}, 0)
      .to(els[5], duration, {delay: delayVal, attr:{x1:200, x2: 360}}, 0)
      .to(els[6], duration*.8, {delay: delayVal, attr:{y2: 307}}, 0)
      .to(els[7], duration*.3, {opacity: 1, delay: duration*.3}, 0)

    if (isMobile()) {
      // exaggerate breathing effect on smaller screens
      breathingEffectLoop
        .to(els[0], duration/2, {ease: Power1.easeInOut, attr:{cx:191}}, 0)
        .to(els[1], duration/2, {ease: Power1.easeInOut, attr:{cx:369}}, 0)
        .to([els[3], els[4]], duration/2, {ease: Power1.easeInOut, attr:{x1:191, x2: 369}}, 0)
        .to(els[5], duration/2, {ease: Power1.easeInOut, attr:{x1:207, x2: 353}}, 0)
        .to(els[6], duration/2*.8, {ease: Power1.easeInOut, attr:{y2: 305}}, 0)

    } else {
      breathingEffectLoop
        .to(els[0], duration/2, {ease: Power1.easeInOut, attr:{cx:195}}, 0)
        .to(els[1], duration/2, {ease: Power1.easeInOut, attr:{cx:365}}, 0)
        .to([els[3], els[4]], duration/2, {ease: Power1.easeInOut, attr:{x1:195, x2: 365}}, 0)
        .to(els[5], duration/2, {ease: Power1.easeInOut, attr:{x1:205, x2: 355}}, 0)
        .to(els[6], duration/2*.8, {ease: Power1.easeInOut, attr:{y2: 304}}, 0)
    }

    // Shrink on scroll
    let controller = new ScrollMagic.Controller()

    if (isDesktop()) {
      let tween = TweenMax.to(this._el, 1, {top: 10, scale: .2, opacity: .5})
      let scene = new ScrollMagic.Scene({duration: 250})
        .setTween(tween)
        .addTo(controller)
      
      let that = this
      let scene2 = new ScrollMagic.Scene({offset: 250})
        .on("enter leave", (e) => {
          // console.log(e.type == "enter" ? "inside" : "outside")
          if (e.type == 'enter') {
            that.logoIsClickable(true)
          } else {
            that.logoIsClickable(false)
          }
        })
        .addTo(controller)
  
    } else {
      let tween = new TimelineMax()
        .to(this._el, .5, {opacity: 0})
        .to(this._el, 0, {top: 10, width: 120, height: 73})
        .to(this._el, .5, {opacity: .5})


      let that = this
      let scene = new ScrollMagic.Scene({offset: 5})
        .setTween(tween)
        .on("enter leave", (e) => {
          // console.log(e.type == "enter" ? "inside" : "outside")
          if (e.type == 'enter') {
            setTimeout(() => {
              that.logoIsClickable(true)
            }, 1000)
          } else {
            // close nav if it's open and user scrolls to top
            // to avoid animation glitch on mobile
            this.props.onLogoClick('scrolled to top')
            that.logoIsClickable(false)
          }
        })
        .addTo(controller)
    }

    // this was too slow, no longer including it
    // var scene = new ScrollMagic.Scene({offset: 10})
    //   // trigger animation by adding a css class
    //   .setClassToggle(this._el, Cx(styles.logoScroll))
    //   .addIndicators({name: "1 - hide seven"}) // add indicators (requires plugin)
    //   .addTo(controller);


    const scene2 = new ScrollMagic.Scene({offset: 200})
      // trigger animation by adding a css class
      .setClassToggle('.' + Cx(styles.seven), Cx(styles.sevenScroll))
      .addTo(controller)

  }

  logoIsClickable = (isClickable) => {
    this.setState({isClickable})
  }

  handleClick = () => {
    if (isDesktop()) {
      if (this.state.isClickable) {
        smoothscroll.polyfill()
        const el = document.querySelector(".logoWord")
        window.scrollTo({ top: el.offsetTop - window.innerHeight/2 + 100, left: 0, behavior: 'smooth' })
      }
    } else if (this.state.isClickable) {
      this.props.onLogoClick(true)
    }
  }
  
  render() {
    const className = Cx(
      'logo',
      styles.logo,
      {[styles.navMobileIsOpen]: this.props.navMobileIsOpen},
      {[styles.isClickable]: this.state.isClickable}
    )


    return (
      <svg
        className={className}
        viewBox="0 0 560 340"
        alt="Tribe7 logo"
        title=""
        ref={c=>{this._el=c}}
        onClick={this.handleClick}
      >
        <g>
          <circle cx="160" cy="170" r="160" stroke="white" strokeWidth="1" fill="none" id="circ1" />
          <circle cx="400" cy="170" r="160" stroke="white" strokeWidth="1" fill="none" id="circ2" />
          <circle cx="280" cy="170" r="160" stroke="white" strokeWidth="1" fill="none" id="circ3" />
          <line x1="160" y1="10" x2="400" y2="10" stroke="white" strokeWidth="1" id="line1" />
          <line x1="160" y1="330" x2="400" y2="330" stroke="white" strokeWidth="1" id="line2" />
          <line x1="240" y1="170" x2="320" y2="170" stroke="white" strokeWidth="1" id="line3" />
          <line x1="280" y1="170" x2="280" y2="276" stroke="white" strokeWidth="1" id="line4" />
          <polygon className={Cx(styles.seven)} id="seven" fill="white" points="371.1,168.2 371.1,169.8 378.2,169.8 372.4,182 374.3,182 380,169.8 380,168.2 "/>
        </g>

      </svg>
    )
  }
}

export default Logo