import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'

import { TweenMax, TimelineMax } from 'gsap'

class LogoBlackwingSignup extends React.Component {

  componentDidMount() {
    let tween = new TimelineMax()
      .fromTo(this._el, 2, {y: -50}, {y: -130}, 0)
      .fromTo("#blackwingSignupTop", 2.5, {scaleX: 0, transformOrigin:"50% 50%"}, {scaleX: 1, transformOrigin:"50% 50%", ease: Power4.easeOut}, 0)
      .fromTo("#blackwingSignupBottom", 2, {scale: 0, transformOrigin:"50% 50%"}, {scale: 1, transformOrigin:"50% 50%", ease: Power4.easeOut}, 0)
  }


  render() {
    const className = Cx(styles.logoBlackwing)

    return (
      <svg
        className={className}
        viewBox="0 0 248.3 124.2"
        alt="Blackwing logo -- signup successful"
        ref={c=>{this._el=c}}>
         >
        <g>
          <path id="blackwingSignupTop" fill="white" d="M248.3,0c-5.6,13-13.3,25-22.5,35.5H22.4C13.2,25.1,5.6,13.2,0,0.3V0H248.3z"/>
          <path id="blackwingSignupBottom" fill="white" d="M159.6,88.7c0,19.6-15.9,35.5-35.5,35.5s-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5S159.6,69.1,159.6,88.7"/>
        </g>
      </svg>

    )
  }
}

export default LogoBlackwingSignup