import React from 'react'
import Responsive from 'react-responsive'

// these should be the same as the breakpoints defined at the bottom of mq.scss
const breakpoints = {
  desktop:      980,
  mobile:       320,
  mobileWide:   375,
  tablet:       740,
  desktop:      980,
  desktopWide:  1327,
  wide:         1300
}

export const Desktop = props => <Responsive {...props} minWidth={breakpoints.desktop} />;
export const Mobile = props => <Responsive {...props} maxWidth={breakpoints.desktop - 1} />;

// test for browser width and user agent for desktop
// browser width doesn't cover iPad landscape
export function isDesktop() {
  return window.innerWidth >= breakpoints['desktop'] && !isMobileOS()
}

export function isLargeScreen(pageResolution) {
  const resolution = pageResolution ? pageResolution : window.innerWidth * window.innerHeight * window.devicePixelRatio
  return (
    pageResolution > 2500000
  )
}

// not being used
// export function isTablet() {
//   return window.innerWidth >= breakpoints['tablet'] && isMobileOS()
// }

export function isTabletLandscape() {
  return (
    window.innerWidth >= breakpoints['desktop'] && 
    isMobileOS() && 
    (window.orientation == 90 || window.orientation == -90)
  )
}

export function isMobile() {
  return window.innerWidth < breakpoints['tablet']
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox/') > -1 || isFirefoxMobile()
}

export function isFirefoxMobile() {
  return navigator.userAgent.toLowerCase().indexOf('fxios/') > -1
}

export function isMobileOS() {
  return isIOS() || isAndroid() || isWindowsTablet();
}

export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isWindowsTablet() {
  return (navigator.userAgent.toLowerCase().indexOf('windows nt') != -1 &&
    navigator.userAgent.toLowerCase().indexOf('touch') != -1);
}



// IE version
export function isIe() {
  var isIeTest = false;
  var ua = navigator.userAgent;
  var re;
  if (navigator.appName == 'Microsoft Internet Explorer') {
    re  = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 );
  } else if (navigator.appName == 'Netscape') {
    re  = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 );
  }
  return isIeTest;
}

export function isEdge() {
  return navigator.userAgent.toLowerCase().indexOf('edge/') > -1;
}

export function isMicrosoft() {
  return isIe() || isEdge();
}

export function isShortWindow() {
  return window.innerHeight < 501;
}


// Add class name to <html> element
export function addClassToDoc(className) {
  document.documentElement.classList.add(className);
};

export function removeClassFromDoc(className) {
  document.documentElement.classList.remove(className);
};

export function addDocumentClasses(className) {

  if(isTabletLandscape()) {
    addClassToDoc('tablet')
    addClassToDoc('landscape')
  } else {
    removeClassFromDoc('tablet')
    removeClassFromDoc('landscape')
  }

  if(isMobileOS()) {
    addClassToDoc('mobileOS')
  } else {
    removeClassFromDoc('mobileOS')
  }

  if(isShortWindow()) {
    addClassToDoc('short')
  } else {
    removeClassFromDoc('short')
  }

  if(isFirefox()) {
    addClassToDoc('firefox')
  } else {
    removeClassFromDoc('firefox')
  }
  
}