import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import NavMobileItem from '../NavMobileItem'
import LogoBlackwingT from '~/app/components/LogoBlackwingT'

class NavMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navMobileIsOpen: false,
      elPositions: []
    }
  }

  componentWillMount() {
    this.getNavItems()

    window.addEventListener('resize', this.getNavItems())
  }

  getNavItems = () => {
    const els = [].slice.call( document.querySelectorAll('[data-navtitle]:not([data-navtitle=""])') )

    let elPositions = new Array()

    els.forEach((element,index) => {
      elPositions.push([
        element.dataset.navtitle,
        element
      ])
    })

    
    this.setState({
      elPositions,
    })
  }

  handleNavMobileIsOpen = (navMobileIsOpen) => {
    this.setState({navMobileIsOpen})

    this.props.onNavMobileOpenChange(navMobileIsOpen)

    this.getNavItems()
  }

  // if Logo sends click action to NavMobile
  componentWillReceiveProps(nextProps) {
    if (nextProps.navMobileIsOpen !== this.state.navMobileIsOpen)
      this.setState({navMobileIsOpen: nextProps.navMobileIsOpen})
  }

  render() {
    return (
      <div
        className={Cx(
          styles.navMobile,
          {[styles.navMobileOpen]: this.state.navMobileIsOpen}
        )}
      >
        { this.state.elPositions.map((el, index) =>
          <NavMobileItem
            key={index}
            navItemTitle={el[0]}
            el={el[1]}
            navMobileIsOpen={this.handleNavMobileIsOpen}

          />
        )}
        <LogoBlackwingT className={styles.logoNav} />
      </div>
    )
  }
}
export default NavMobile