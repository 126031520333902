@import "../../helpers/mq";

.navMobile {
  position: fixed;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &Open {
    display: flex;
  }
}

body .logoNav {
  width: 50px;
  right: auto;
  left: 50%;
  transform: translatex(-50%);
  bottom: 92px;
  opacity: 1!important;

  @include mq($until: desktop, $and: '(max-height: 500px)') {
    display: none;
  }
}