@import "../../helpers/mq";

.container {
  padding: 0 100px;

  @include mq($until: desktop) {
    padding: 0 8.333%;
  }
}

.containerIntroAnimating {
  overflow: hidden;
  height: 100vh;
}

.landing {
  height: 100vh;
  position: relative;
}


/* temporary */
.spacer {
  height: 50vh;
}

.trigger {
  position: relative;
  top: calc(-50vh + 80px);
}

b {
  color: white;
}


.graphic {
  height: 240px;
  display: inline-block;
  margin: 0 57px 0;
  transition: opacity 1.6s, transform 2s;
  // scaleX was set to start from 0.01 to prevent the animation from breaking
  // When starting at 0 the bundler transpiles it to matrix and it breaks
  // See this thread: https://github.com/parcel-bundler/lightningcss/issues/288
  transform: scaleX(0.01) translateY(35px);
  opacity: 0;
  vertical-align: middle;
  will-change: transform, opacity;


  @include mq($until: desktop) {
    margin: 0 40px;
  }

  @include mq($until: tablet) {
    margin: 0 5%;
    height: 180px;
  }

  @include mq($until: mobileWide) {
    margin: 0 10px;
  }

}

.graphicSection {
  text-align: center;
  margin-top: 25vh;

  img:nth-child(3) { 
    height: 220px; 
    transition: opacity 1.6s, transform 1.5s;
    @include mq($until: tablet) {
      height: 160px;
    }   
  }
  img:nth-child(4) { 
    height: 200px; 
    transition: opacity 1.6s, transform 2s;
    @include mq($until: tablet) {
      height: 140px;
    }   
  }
  img:nth-child(5) { 
    height: 180px; 
    transition: opacity 1.6s, transform 2.5s;
    @include mq($until: tablet) {
      height: 120px;
    }   
  }
  img:nth-child(6) { 
    height: 160px; 
    transition: opacity 1.6s, transform 3s;
    @include mq($until: tablet) {
      height: 100px;
    }   
  }

}


.blackwingTextLogo {
  display: block;
  margin: 25vh auto 0;
  width: 700px;
  max-width: 100%;
  opacity: 0;
  transition: opacity 1.6s, transform 2s;
  transform: scaleX(1) translateY(35px);  
  filter: drop-shadow( 0px 0px 5px rgba(255,255,255,0.3) );

  @include mq($from:tablet, $until: desktop, $and: '(orientation: landscape)') {
    max-width: calc(100vw - 280px);
  }
  
}

.graphicShow {
  opacity: 1;
  transform: scaleX(1) translateY(-35px);
}



.signupSection {
  margin: 240px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-transform: uppercase;
  text-align: center;

  color: var(--grey);

  @include mq($until: tablet) {
    margin: 120px 0;
  }
}
.signupSection b {
  color: white;
}

.contact {
  margin-top: 120px;
  margin-bottom: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-transform: uppercase;
  text-align: center;

  color: var(--grey);

  @include mq($until: tablet) {
    margin-top:0;
  }

  a {
    color: white;
    transition: .3s opacity;
    font-weight: bold;

    &:hover {
      opacity: .5;
    }
  }
  
}

.navHoverDiv {
  transition: opacity .5s; 
  opacity: 1;
}
.navHovering {
  opacity: 0;
}

.navMobileOpen {
  opacity: 0;
}

.social {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 20px;
    transition: .3s opacity;
    padding: 2px 10px 10px;

    &:hover {
      opacity: .5;
    }

    sup {
      top: -0.28em;
    }
  }
  img {
    margin: 22px auto 0;
    display: block;
    width: 20px;
    filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
  }
}

.credit {
  text-align: center;
  margin-bottom: 80px;
  font-size: 15px;
  color: var(--grey);

  span {
    color: white;
  }

  a {
    transition: .3s opacity;
  }

  a:hover {
    opacity: .5;
  }
}

.logoLink {
  transition: .3s opacity;
  display: inline-block;

  &:hover {
    opacity: .5;

    // svg, img {
    //   opacity: .3;
    // }
  }
}