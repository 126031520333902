import React, {
  Component,
  Fragment
} from 'react'
import Cx from 'classnames'
import styles from './styles.css'

import { TweenMax, TimelineMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"


class LogoBlackwing extends React.Component {

  componentDidMount() {

    let controller = new ScrollMagic.Controller()

    let tween = new TimelineMax()
      .fromTo("#blackwingTop", 2.4, {scaleX: 0, transformOrigin:"50% 50%"}, {scaleX: 1, transformOrigin:"50% 50%", ease: Power4.easeOut}, 0)
      .fromTo("#blackwingBottom", 2, {scale: 0, transformOrigin:"50% 50%"}, {scale: 1, transformOrigin:"50% 50%", ease: Power4.easeOut}, 0)
      .fromTo("#blackwingSeven", .8, {opacity: 0}, {delay: 1, opacity: 1}, 0)

    const scene = new ScrollMagic.Scene({
      triggerElement: this._el,
      offset: 0 - window.innerHeight/2 + 30
    })
      .setTween(tween)
      // .addIndicators({name: "blackwing - 1 (duration: 0)"}) // add indicators (requires plugin)
      .addTo(controller)
  }


  render() {
    const className = Cx(styles.logoBlackwing)

    return (
      <Fragment>
        <svg
          className={className}
          viewBox="0 0 248.3 124.2"
          alt="Blackwing logo"
          ref={c=>{this._el=c}}>
           >
          <defs>

            <filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
              <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="thicken" />
              <feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />
              <feFlood floodColor="rgba(255,255,255,.5)" result="glowColor" />
              <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
              <feMerge>
                <feMergeNode in="softGlow_colored"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>

          </defs>
          <g>
            <path id="blackwingTop" fill="black" d="M248.3,0c-5.6,13-13.3,25-22.5,35.5H22.4C13.2,25.1,5.6,13.2,0,0.3V0H248.3z"/>
            <path id="blackwingBottom" fill="black" d="M159.6,88.7c0,19.6-15.9,35.5-35.5,35.5s-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5S159.6,69.1,159.6,88.7"/>
          </g>
          <polygon id="blackwingSeven" filter="url(#sofGlow)" fill="white" points="171.3,60.3 171.3,61.4 176.2,61.4 172.2,69.8 173.5,69.8 177.5,61.4 177.5,60.3 "/>
        </svg>
        <div data-navtitle="Blackwing<sup>7</sup>" className="navPin" style={{position: 'relative', top: "75px"}}/>
      </Fragment>

    )
  }
}

export default LogoBlackwing