import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import LogoTTop from '../../assets/img/logoT-top.png'
import LogoTCircle from '../../assets/img/logoT-circle.png'
import { TweenMax } from 'gsap'

class LogoBlackwingT extends React.Component {

  componentDidMount() {
    TweenMax.to(
      this._el,
      1,
      {opacity: 1},
    )
  }

  render() {
    return (
      <div 
        className={Cx(styles.logo, this.props.className)}
        ref={c=>{this._el=c}}
      >
        <a href="https://www.blackwing7.com">
          <img 
            ref={c=>{this._topPath=c}}
            className={Cx(styles.topPath)}
            src={LogoTTop}
          />
          <img 
            ref={c=>{this._circlePath=c}}
            className={Cx(styles.circlePath)}
            src={LogoTCircle}
          />
          <span className={styles.hover}>BLACKWING<sup>7</sup></span>
        </a>
      </div>
    )
  }
}

export default LogoBlackwingT