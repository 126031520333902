import React from 'react'
import Cx from 'classnames'

import { TweenMax, TimelineMax } from 'gsap'

class ThankYou extends React.Component {

  componentDidMount() {
    console.log('ThankYou mounted');

    let tween = new TimelineMax()
      .fromTo(this._el, 2, {y: -60}, {y: -110}, .3)
      .fromTo(this._el, 1.5, {opacity: 0}, {opacity: 1}, .3)
  }


  render() {
    return (
      <div ref={c=>{this._el=c}} style={{ willChange: 'transform'}}>
        THANK YOU
      </div>
    )
  }
}

export default ThankYou