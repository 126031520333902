.backgroundSimple {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: .6;
}

.img {
  width: 100%;
  height: 100%;
  position:absolute;
  z-index: 1;
  background-position: center;
  background-size: cover;
}
