import React from 'react'

import NavItem from '../NavItem'
import { isFirefox } from '~/app/helpers/helpers'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollY: 0,
      scrollBarRate: 0,
      bodyHeight: 1,
      elPositions: [],
      navHover: false,
      anchorClicked: false,
      viewportHeight: 1
    }
    this.UpdateNav = this.UpdateNav.bind(this)
    this.UpdateElementPositions = this.UpdateElementPositions.bind(this)
    this.handleAnchorClick = this.handleAnchorClick.bind(this)
    this.handleAnchorHover = this.handleAnchorHover.bind(this)
  }

  componentWillMount() {
    this.UpdateElementPositions()
  }

  componentDidMount() {
    this.UpdateNav()

    TweenMax.fromTo(this._el, 1, {x: 80}, {x: 0, delay: 0} )
    TweenMax.fromTo(this._el, 1, {opacity: 0}, {opacity: 1, delay: 0} )
    
    document.addEventListener('scroll', this.UpdateNav)
    window.addEventListener('hashchange', this.UpdateNav)
    document.addEventListener('click', this.UpdateNav)

    window.addEventListener('resize', this.UpdateElementPositions)
  }

  UpdateNav() {
    let viewportHeight = window.innerHeight //A
    let bodyElement = document.querySelector('body')//B1
    let rect = bodyElement.getBoundingClientRect()//B2
    let bodyHeight = rect.height //B3
    let scrollMax = Math.ceil( bodyHeight - viewportHeight ) //C = B3 - A
    let scrollY = document.documentElement.scrollTop || document.body.scrollTop//D
    // let scrollRate = parseInt( (scrollY / scrollMax) * 100, 10 ); //E = (D / C) *100

    this.setState({
      scrollY,
      // scrollBarRate: scrollRate,
      bodyHeight,
      viewportHeight
    })

  }

  UpdateElementPositions() {
    const viewportHeight = window.innerHeight
    const els = [].slice.call( document.querySelectorAll('.navPin') )
    const bodyHeight = document.querySelector('body').scrollHeight
    let elPositions = new Array()

    els.forEach((element,index) => {
      elPositions.push([
        element.offsetTop / bodyHeight * 100, // the vertical position of the pin in the nav
        element.offsetTop - innerHeight/2 + element.offsetHeight/2,
        element.dataset.navtitle,
        element.offsetTop, // for scrolling to the main elements on the page

      ])
    })

    this.setState({
      elPositions,
      viewportHeight
    })

    this.UpdateNav()
  }


  // the handles are to make sure that when user hovers:
  // ** the Nav changes appearance
  // ** the hover state lifts up to Home when user hovers on the nav
  // when user clicks an anchor:
  // ** user scrolls to the corresponding section
  // nav hover state is deactivated (even though user's mouse is still hovering on it)
  // hover state is reactivated when user hovers out of Nav or hovers on an anchor link
  handleMouseOver() {
    // console.log("anchorClicked");
    // console.log(this.state.anchorClicked);
    if (!this.state.anchorClicked) {
      this.setState({ navHover: true })
      this.props.onNavHover(true)
    }
  }

  handleMouseOut() {
    this.setState({ navHover: false, anchorClicked: false })
    this.props.onNavHover(false)
  }

  handleAnchorClick(anchorClicked) {
    this.setState({ navHover: false})
    this.props.onNavHover(false)
  }
  
  handleAnchorHover(anchorHover) {
    this.setState({ anchorClicked: false})
    this.setState({ navHover: true })
    this.props.onNavHover(true)
  }
  
  render() {

    // hover glitch on firefox when clicking the right side of internalLink
    const navWidth = isFirefox() ? '80px' : '110px'

    return (
      <nav
        style={{
          position: 'fixed',
          top: '0',
          right: '0',
          width: this.state.navHover ? "220px": navWidth,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          zIndex: "9"
        }}
        ref={c=>{this._el=c}}
        onMouseLeave={this.handleMouseOut.bind(this)}

      >
        <div
          style={{
            margin: "35px 0",
            height: "100%",
            width: navWidth,
            alignSelf: "flex-end",
            maxHeight: "500px",
            position: "relative"
          }}
          onMouseEnter={this.handleMouseOver.bind(this)}
        >
          <div style={{
            marginLeft: isFirefox() ? '' : '30px',
            position: "relative",
            height: "100%"
          }}>
            { this.state.elPositions.map((elPosition, index) =>
              <NavItem
                key={index}
                scrollY={this.state.scrollY}
                bodyHeight={this.state.bodyHeight}
                position={elPosition}
                navItemTitle={elPosition[2]}
                element={elPosition[3]}
                navHover={this.state.navHover}
                onAnchorClick={this.handleAnchorClick}
                onAnchorHover={this.handleAnchorHover}
              />
            )}
          </div>
        </div>
      </nav>
    )
  }
}


export default Nav