const _temp0 = require("../../assets/img/lensIcons/lens1.svg");
const _temp1 = require("../../assets/img/lensIcons/lens2.svg");
const _temp2 = require("../../assets/img/lensIcons/lens3.svg");
const _temp3 = require("../../assets/img/lensIcons/lens4.svg");
const _temp4 = require("../../assets/img/lensIcons/lens5.svg");
module.exports = {
  "lens1": _temp0,
  "lens2": _temp1,
  "lens3": _temp2,
  "lens4": _temp3,
  "lens5": _temp4
}