@import "../../helpers/mq";


.wrap {
  margin: 0 auto;
  max-width: 1072px;
  padding: 0 80px;
  color: var(--grey);


  @include mq($until: tablet) {
    padding: 80px 0 0;
  }
}

.inner {
}

.notice {
  margin-bottom: 60px;
  transition: opacity 1s;
}

.success .notice  {
  opacity: 0;
}

.form {
  transition: opacity .4s;
}

.form input {
  width: 100%;
  text-align:center;
  padding: 0 20px;
  margin-bottom: 4px;
  outline: none;
  font: var(--f1);
  color: white;
  letter-spacing: 0.115em;
}

.form input::placeholder {
  color:white;
}

.line {
  border-bottom: 1px solid var(--grey);
  transform: scaleX(0);
  transition: transform 1.4s;
}

.line.lineShow {
  transform: scaleX(1);
}

.form button {
  font: var(--f1);
  letter-spacing: 0.115em;
  background: white;
  color: black;
  font-weight: bold;
  padding: 11px 30px;
  margin-top: 51px;
  /*transform: scaleX(0) translateY(10px);*/
  opacity: 0;
  transition: opacity .4s;
  outline: none;
}

.form .buttonShow {
  opacity: 1;
}

.success .form {
  opacity: 0;
  pointer-events: none;
}

.tLogo {
  display: block;
  margin: 0 auto;
  width: 120px;

  transition: opacity 1.6s, transform 1.8s;
  transform: scaleX(0) translateY(0px);
  opacity: 0;
}

.success .tLogo {
  opacity: 1;
  transform: scaleX(1) translateY(-100px);
}

.externalLinkButton {
  display: block;
  margin: 10px auto 0;
  width: 20px;
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
}