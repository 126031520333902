import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'


import { isDesktop, isIOS } from '~/app/helpers/helpers'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"
import smoothscroll from 'smoothscroll-polyfill'

import logoWordImg from '~/app/assets/img/logo-word.svg'


class logoWord extends React.Component {

  componentDidMount() {
    if ( !isIOS() && isDesktop()) {
      let controller = new ScrollMagic.Controller()
      let scene = new ScrollMagic.Scene({offset: this._el.offsetTop - window.innerHeight + 180})
        // trigger animation by adding a css class
        .setClassToggle(this._el, Cx(styles.logoWordScroll))
        .addTo(controller)
    }

  }

  render() {
    // logoWord class is referenced in Arrow component
    return (
      <img className={Cx(styles.logoWord, 'navPin logoWord')} data-navtitle="Tribe<sup>7</sup>" ref={c=>{this._el=c}} src={logoWordImg} />
    )
  }
}

export default logoWord