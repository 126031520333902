const _temp0 = require("../../assets/img/background/1-Miles2.jpg");
const _temp1 = require("../../assets/img/background/2-decarava.jpg");
const _temp2 = require("../../assets/img/background/2a-Denis.jpg");
const _temp3 = require("../../assets/img/background/3-basquiat.jpg");
const _temp4 = require("../../assets/img/background/4-stalker.jpg");
const _temp5 = require("../../assets/img/background/5-solaris.jpg");
const _temp6 = require("../../assets/img/background/5a-jdilla.jpg");
const _temp7 = require("../../assets/img/background/6-maurice.jpg");
const _temp8 = require("../../assets/img/background/7-In-the-Mood-for-Love.jpg");
const _temp9 = require("../../assets/img/background/8-reels.jpg");
const _temp10 = require("../../assets/img/background/9-wkwai.jpg");
const _temp11 = require("../../assets/img/background/Herbie-Hancock.jpg");
const _temp12 = require("../../assets/img/background/Sugimoto.jpg");
const _temp13 = require("../../assets/img/background/Sunshine.jpg");
const _temp14 = require("../../assets/img/background/Walkabout.jpg");
const _temp15 = require("../../assets/img/background/ayler.jpg");
const _temp16 = require("../../assets/img/background/sun-bush_mama_2.jpg");
const _temp17 = require("../../assets/img/background/z-dancing.jpg");
const _temp18 = require("../../assets/img/background/z-moolade.jpg");
const _temp19 = require("../../assets/img/background/z-with_camera.jpg");
module.exports = {
  "1-Miles2": _temp0,
  "2-decarava": _temp1,
  "2a-Denis": _temp2,
  "3-basquiat": _temp3,
  "4-stalker": _temp4,
  "5-solaris": _temp5,
  "5a-jdilla": _temp6,
  "6-maurice": _temp7,
  "7-In-the-Mood-for-Love": _temp8,
  "8-reels": _temp9,
  "9-wkwai": _temp10,
  "Herbie-Hancock": _temp11,
  "Sugimoto": _temp12,
  "Sunshine": _temp13,
  "Walkabout": _temp14,
  "ayler": _temp15,
  "sun-bush_mama_2": _temp16,
  "z-dancing": _temp17,
  "z-moolade": _temp18,
  "z-with_camera": _temp19
}