.item {
  position: absolute;          
  right: 0; 
  padding: 12px 0;
  transition: width .4s;
}
.itemHasAnchor {
  cursor: pointer;
}
.itemHasAnchor:hover {
  opacity: .5!important;
}
.itemHasAnchor:hover .inner {
  height: 2px;
}

.inner {
  background: white;
  height: 1px;
  transition: opacity .1s;
}

.wedge {
  position: absolute;
  top: 9px;
  left: -20px;
  width: 0px;
  height: 0px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 9px solid #ffffff;  
  pointer-events: none;
}

.internalLink {
  position: absolute;
  top: -3px;
  right: calc(100%);
  padding: 5px 20px 5px 5px;
  font-size: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  /* pointer-events: none; */
}