@import "../../helpers/mq";

.logo {
  position: fixed;
  height: 400px;
  max-height: calc(100vh - 160px);
  width: 1164px;  
  max-width: calc(10 / 12 * 100vw);
  top: 50%; // specified in js
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 6;
  will-change: transform;
  opacity: 0;
  -webkit-backface-visibility: hidden; // fixes gpu glitch on iOS devices
  background-color: rgba(0,0,0,0.01); // fixes gpu glitch on iOS devices
  /* TODO: check cross-browser compatibility for drop-shadow */
  /*filter: drop-shadow( 0px 0px 7px rgba(255,255,255,0.5) );*/

  @include mq($until: mobileWider) {
    height: 250px;
  }


  // to target oversize phones and phablets in landscape
  :global(.short.mobileOS) & {
    height: 60%;
    transform: translateY(-60%);
  }

  :global(.short.mobileOS.firefox) & {
    height: auto;
    max-height: calc(100vh - 110px);
    transform: translateY(-46%);
  }

  // landscape on phones
  @include mq($until: tablet, $and: '(orientation: landscape)') {
    height: 60%;
    transform: translateY(-60%);
    // top: calc(37%);
  }

}

.isClickable {
  cursor: pointer;
  
  @include mq($until: desktop) {
    transition: .8s all;
  }
}
@media only screen and (max-device-width: 1024px) and (orientation:landscape) {
  .isClickable {
    transition: .8s all;
  }
}

.navMobileIsOpen {
  top: 53px !important;
  opacity: 1 !important;

  // @include mq($until: tablet, $and: '(orientation: landscape)') {
  //   top: 15px!important;
  // }
}

.seven {
  opacity: 0;
}

.sevenScroll {
  transition: opacity .3s;
  opacity: 0!important;
}

.logoMobileScroll {
  left: 0;
  right: 0;
  margin: 0 auto;  
}