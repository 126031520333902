@import "../../helpers/mq";

.textBox {
  text-transform: uppercase;
  max-width: 620px;
  position: relative;
  margin: 80px 0;
  color: rgb(193,193,193); // for ie
  color: var(--grey);
  top: 60px;
  /*text-shadow: 0 0 2px rgba(0,0,0,0.3);*/

  i {
    font-family: "Sectra", serif;  
    font-size: 18px;
    text-transform:none;
    letter-spacing: .4px;
  }

  &:not(.keepOnMobile) br {
    @include mq($until: desktopWide) {
      display: none;
    }
  }

  br {
    @include mq($until: mobileWider) {
      display: none;
    }
  }

}

.textBoxShow {
  opacity: 1!important;
  top: 0px;
}

.trigger {
  position: relative;
  top: calc(-50vh + 80px);
}

.textBoxCol {

  @include mq($from: tablet) {
    &1{
      margin-left: calc(1 / 12 * 100%);
      width: calc((12 - 1) / 12 * 100% - 45px);
    }
    &2{
      margin-left: calc(2 / 12 * 100%);
      width: calc((12 - 2) / 12 * 100% - 45px);
    }
    &3{
      margin-left: calc(3/ 12 * 100%);
      width: calc((12 - 3) / 12 * 100% - 45px);
    }
    &4{
      margin-left: calc(4/ 12 * 100%);
      width: calc((12 - 4) / 12 * 100% - 45px);
    }
    &5{
      margin-left: calc(5/ 12 * 100%);
      width: calc((12 - 5) / 12 * 100% - 45px);

      @include mq($until: desktop) {
        width: calc((12 - 5) / 12 * 100%);
      }
    }
    &6{
      margin-left: calc(6/ 12 * 100%);
      width: calc((12 - 6) / 12 * 100% - 45px);

      @include mq($until: desktop) {
        width: calc((12 - 5) / 12 * 100%);
      }
    }
    &7{
      margin-left: calc(7/ 12 * 100%);
      width: calc((12 - 7) / 12 * 100% - 45px);

      @include mq($until: desktopWide) {
        margin-left: calc(4/ 12 * 100%);
        width: calc((12 - 4) / 12 * 100% - 45px);
      }

      @include mq($until: desktop) {
        width: calc((12 - 5) / 12 * 100%);
      }
    }
  }
}