import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'

import { TimelineMax } from 'gsap'
import { isFirefoxMobile, isMobileOS } from '../../helpers/helpers'
import backgroundImages from '../../assets/img/background/*.jpg'

class Background extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageResolution: window.innerWidth * window.innerHeight * window.devicePixelRatio
    }
  }

  componentDidMount() {
    
    // load all the lines, convert Nodelist to array
    let els = [].slice.call(
      this._el.querySelectorAll(`.${styles.background} div`)
    )

    let images = new Array()
    for (let key in backgroundImages) {
      images.push(backgroundImages[key])
    }
    this.loadImagesInSequence(images.slice(1))

    let that = this
    // Use GSAP only to time the adding/removal of classes
    setTimeout(() => {
      els.forEach((element,index) => {

        let isFirstChild = index == 0 ? true : false
        let isSecondChild = index == 1 ? true : false
        let secondChildEl = index == 1 ? els[0] : null
        let nextElement = index == els.length - 1 ? els[0] : els[index + 1]

        // give cellular data connections a little more time to load each image
        let fadeInDuration = isMobileOS() ? 9 : 8
        let repeatDelay = (els.length-2)*fadeInDuration
        let delay = index*fadeInDuration

        new TimelineMax({repeat: -1, delay, repeatDelay}).
          to(
            element,
            fadeInDuration*2,
            {
              onStart: that.fadeIn,
              onStartParams: [element, nextElement, isFirstChild, isSecondChild, secondChildEl],
              onComplete: that.fadeOut,
              onCompleteParams: [element, isFirstChild],
            },
          )

      })
    }, 10)

  }

  loadImagesInSequence = (images) => {

    if (!images.length) {
      return
    }
  
    let img = new Image(),
      url = images.shift()
  
    let that = this
    img.onload = function(){ that.loadImagesInSequence(images) }
    img.src = url
  
  }

  fadeIn(el, nextEl, firstChild, secondChild, firstChildEl) {
    el.classList.remove(Cx(styles.inactive))
    el.classList.add(Cx(styles.active))

    nextEl.style.backgroundImage = `url(${nextEl.getAttribute('data-background-image')})`

    if (isFirefoxMobile()) {
      // to fix viewport height issue on firefox mobile
      // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser#comment61769040_37113430
      const heightOverride = window.innerHeight + 100
      el.style.height = `${heightOverride}px`
    }
    

    if (firstChild) {
      el.style.backgroundImage = `url(${el.getAttribute('data-background-image')})`
      el.classList.add(Cx(styles.activeFirstChild))
    }

    if (secondChild)
      firstChildEl.classList.remove(Cx(styles.activeFirstChild))

  }

  fadeOut(el, firstChild) {
    el.classList.remove(Cx(styles.active))
    el.classList.add(Cx(styles.inactive))

    // improves performance on safari
    el.style.backgroundImage = `none`

    if (firstChild)
      el.classList.remove(Cx(styles.activeFirstChild))
  }

  render() {

    let images = new Array()

    for (let key in backgroundImages) {
      images.push(backgroundImages[key])
    }

    // only load the first 8 images on mobile — for slow cellular data connections
    if (isMobileOS()) {
      images = images.slice(0, 8)
    }

    // to fix viewport height issue on firefox mobile
    let heightOverride = null
    if (isFirefoxMobile() && heightOverride === null) {
      heightOverride = window.innerHeight
    }

    return (
      <div className={Cx(styles.background)} ref={c=>{this._el=c}}>

        { images.map((item, i) => {
          return <div key={i} className={Cx(styles.img)} data-background-image={item} style={{height: heightOverride}}/>
        }) }

      </div>
    )
  }

}

export default Background