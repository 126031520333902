@import "../../helpers/mq";

.logoWord {
  display: block;
  margin: 10vh auto -40px;
  max-width: 800px;
  filter: drop-shadow( 0px 0px 5px rgba(255,255,255,0.3) );
  transition: transform 2s;
  transform: translateY(35px);

  position: relative;

  @include mq($from:tablet, $until: desktop) {
    max-width: calc(100vw - 220px);
  }
  @include mq($from:tablet, $until: desktopWide, $and: '(orientation: landscape)') {
    max-width: calc(100vw - 280px);
  }
}

.logoWordScroll {
  transform: translateY(-35px);
}