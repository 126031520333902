.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: .6;
}

.img {
  opacity: 0;
  width: 100%;
  height: 100%;
  position:absolute;
  z-index: 1;
  background-position: center;
  background-size: cover;
  transition: opacity 8s linear, transform 20s linear;
  will-change:transform, opacity;
}

.active {
  opacity: 1;
  transform: scale(1.15);
}
.activeFirstChild {
  z-index: 2;
}
.inactive {
  opacity: 0!important;
  transform: none;
  transition: none;
}