import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Home from './Home'
import { Helmet } from "react-helmet"

import { isDesktop, addDocumentClasses } from '~/app/helpers/helpers'

class Root extends React.Component {
  componentDidUpdate(prevProps) {
    // Scroll to top on URL Change
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    } else if (!isDesktop()) {
      // scroll to top on refresh on mobile
      window.scrollTo(0, 0)
    }
  }

  componentDidMount() {
    
  }

  render() {
    const meta = {
      title: "TRIBE7",
      description: "Exploring how color, light and optics can be reimagined for contemporary filmmakers."
    }

    return <div>
      {/* This is a good place to include a navigation component */}
      {/* Moved Nav to Home for now… need to figure out how to load Nav after Home is mounted*/}
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
      </Helmet>
      <Switch>
        <Route path='/' exact component={Home} />
        <Redirect to="/" />
      </Switch>
      {/* This is a good place to include a footer component */}
    </div>
  }
}

export default withRouter(Root)