import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'

import smoothscroll from 'smoothscroll-polyfill'


class NavItem extends React.Component {
  constructor(props) {
    super(props)
  }

  getElementPosition = (elPosition) => {
    return  100 -
      100 *
      (
        Math.max(
          Math.pow(
            Math.abs(elPosition - this.props.scrollY) /
              this.props.bodyHeight,
            .3
          ),
          .2
        ) *
        1.1 -
        .2
      )
  }

  getPinWidth = (elPosition) => {
    return this.getElementPosition(elPosition) + '%'
  }

  getPinOpacity = (elPosition) => {
    return this.getElementPosition(elPosition)/100
  }

  handleClick = () => {
    if (this.props.navItemTitle) {
      this.props.onAnchorClick(true)

      smoothscroll.polyfill()
      window.scrollTo({ top: this.props.position[1], left: 0, behavior: 'smooth' })
    }


  }

  // this reenables the hover state on the nav so users dont have to hover way from the nav to click multiple links
  handleAnchorHover = () => {
    if (this.props.navItemTitle) {
      this.props.onAnchorHover(true)
    }
  }

  render() {

    const itemOpacity = this.props.navHover ? 1 : this.getPinOpacity(this.props.position[1])
    let itemWidth = "50%"

    if (this.props.navHover) {
      if (this.props.navItemTitle !== undefined) {
        itemWidth = "100%"
      }
    } else {
      itemWidth = this.getPinWidth(this.props.position[1])
    }
    // const transition = this.props.navHover ? "all .1s" : ""

    return (
      <div
        className={Cx(
          styles.item,
          {[styles.itemHasAnchor] : this.props.navItemTitle}
        )}
        style={{
          width: itemWidth,
          top: `${this.props.position[0]}%`,
          opacity: itemOpacity
        }}
        onClick={this.handleClick}
        onMouseEnter={this.handleAnchorHover}
      >
        <div className={Cx(styles.inner)}>
          {this.props.navItemTitle && !this.props.navHover &&
            <div className={Cx(styles.wedge)} />
          }
          {this.props.navItemTitle && this.props.navHover &&
            <div className={Cx(styles.internalLink)} dangerouslySetInnerHTML={{__html: this.props.navItemTitle}}/>
          }
        </div>
      </div>
    )
  }
}


export default NavItem