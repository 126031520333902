import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import backgroundImages from '../../assets/img/background/*.jpg'

class BackgroundSimple extends React.Component {

  render() {

    let images = new Array()

    for (let key in backgroundImages) {
      console.log({img: backgroundImages[key]})
      images.push(backgroundImages[key])
    }

    return (
      <div className={Cx(styles.backgroundSimple)}>
        <div className={Cx(styles.img)} style={{backgroundImage: `url(${images[0]})`}}/>
      </div>
    )
  }

}

export default BackgroundSimple