import React, {
  Component,
  Fragment
} from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import { isIOS, isDesktop } from '~/app/helpers/helpers'

import { TweenMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"


class TextBox extends React.Component {

  componentDidMount() {
    if (!isIOS() && isDesktop()) {

      const controller = new ScrollMagic.Controller()

      // apply parallax effect
      const tween = new TimelineMax()
        .add([
          TweenMax.fromTo(this._el, 1, {y: this.props.yFrom}, {y: this.props.yTo, ease: Linear.easeNone})
        ])
      new ScrollMagic.Scene({triggerElement: this._trigger, duration: window.innerHeight})
        .setTween(tween)
        .addTo(controller)

      // apply fade/slide in effect
      new ScrollMagic.Scene({triggerElement: this._trigger, offset: this.props.yFrom*.85})
        .setClassToggle(this._el, Cx(styles.textBoxShow))
        .addTo(controller)
    }
  }


  render() {
    // randomly vary the slide-in animation speed between .75-1.5s
    const transition = `opacity .8s, top ${Math.random() * .75 + .75}s`
    const opacity = (!isIOS() && isDesktop()) ? 0 : 1

    return (
      <Fragment>
        <div className={styles.trigger} ref={c=>{this._trigger=c}}/>
        <div
          className={Cx(
            styles.textBox,
            {'navPin': this.props.isPin},
            {[styles.keepOnMobile]: this.props.keepLineBreaksOnMobile},
            styles[`textBoxCol${this.props.col}`],
          )}
          ref={c=>{this._el=c}}
          style={{
            // left: left,
            // width: width,
            opacity,
            transition }}
        >
          {this.props.children}
        </div>
      </Fragment>
    )
  }
}

export default TextBox
