@import "../../helpers/mq";

.logo {
  position: fixed;
  z-index: 11;
  width: 50px;
  bottom: 39px;
  right: 39px;
  opacity: 0;
  cursor: pointer;

  @include mq($from: mobile, $and: '(max-height: 635px)') {
    display: none;
  }

  &:hover {
    .topPath, .circlePath {
      @include mq($from: tablet) {
        opacity: 0;
      }
    }
    .hover {
      @include mq($from: tablet) {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }

  a {
    display: block;
    position: relative;
  }
}

.topPath, .circlePath {
  will-change: transform;
  opacity: .5;
  transition: .3s opacity;

  @include mq($until: tablet) {
    opacity: 1;
  }
}

.topPath {
  display: block;
  margin: 0 auto;
  width: 100%;
  // transition: 1.2s transform;
  transform-origin: center center;
}
.hidden .topAnimating {
  transform: scaleX(1);
}

.circlePath {
  display: block;
  margin: 2px auto 0;
  width: 30.53%;
  transform-origin: 50% 68%;  
  // transition: 1.2s transform;
}

.hover {
  position: absolute;
  font-size: 13px;
  left: 50%;
  display: block;
  top: 3px;
  opacity: 0;
  transform: translate(-50%, 2px);
  transition: .3s all;
}