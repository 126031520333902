import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import { TimelineMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators"
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"
import smoothscroll from 'smoothscroll-polyfill'

import { isMobile, isMobileOS, isShortWindow } from '~/app/helpers/helpers'


class Arrow extends React.Component {

  componentDidMount() {

    let tl = new TimelineMax()


    tl.fromTo(this._wrap, 1, {scaleY: 0}, {scaleY: 1, delay: 3.5} )


    let controller = new ScrollMagic.Controller()
    let scene = new ScrollMagic.Scene({offset: 50})
      // trigger animation by adding a css class
      .setClassToggle(this._wrap, Cx(styles.arrowHide))
      .addTo(controller)


  }

  scrollDown() {
    smoothscroll.polyfill()
    const el = document.querySelector(".logoWord")
    window.scrollTo({ top: el.offsetTop - window.innerHeight/2 + 100, left: 0, behavior: 'smooth' })
  }

  heightOverride = () => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    if (isMobile() || (isShortWindow() && isMobileOS() )) {
      return {
        bottom: 'auto',
        top: `${window.innerHeight * .9 - 18}px`
      }
    }
  }

  render() {
    return (
      <div
        className={Cx(styles.wrap)}
        ref={c=>{this._wrap=c}}
        style={
          this.heightOverride()
        }
      >
        <svg className={Cx(styles.arrow)} ref={c=>{this._el=c}} viewBox="0 0 56.4 25.7">
          <g>
            <polyline strokeWidth="2" fill="transparent" stroke="white" points="0.3,0.4 28.2,25 56,0.4  "/>
          </g>
        </svg>
        <div className={Cx(styles.hoverText)} onClick={this.scrollDown}>SCROLL</div>
      </div>
    )
  }
}

export default Arrow