import React, { Component } from "react";
import { render } from "react-dom";

import MailchimpSubscribe from '../MailchimpSubscribe'

class MailchimpDemo extends Component {
  render() {
    const url =
      "//7isatribe.us20.list-manage.com/subscribe/post?u=0857837aeb237c6d9e69f70fa&id=713afd95a9";

    return (
      <div>
        <MailchimpSubscribe url={url} />
      </div>
    );
  }
}

export default MailchimpDemo;
