@import "../../helpers/mq";

.wrap {
  width: 100px;
  display: block;
  margin: 0 auto;

  position:absolute;
  bottom: 6.3%;
  left: 50%;
  transform: translateX(-50%);
  cursor:pointer;
  transition: opacity .5s;
  text-align: center;


  :global(.mobileOS) & {
    bottom: 110px;
  }

  :global(.tablet.landscape) & {
    bottom: 95px;
  }

  :global(.firefox.mobileOS) & {
    bottom: 6.3%;
  }

  // for wide and narrow devices like iPhone X, X Max
  // :global(.short.mobileOS) & {
  //   bottom: 70px;
  // }
  
  // position on phones is handled in javascript
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/  
}

.arrow {
  margin: 0 auto;
  width: 50px;
  transform: opacity .5s;
  transition: opacity .5s;
  opacity: 1;

}
.wrap:hover .arrow{
  opacity: 0;
}

.arrowHide .arrow {
  opacity: 0;
}

.hoverText  {
  height: 0;
  opacity: 0;
  transform: translateY(-25px);
  transition: opacity .5s, transform .5s;
}
.wrap:hover .hoverText{
  opacity: 1;
  transform: translateY(-31px);
}
.arrowHide .hoverText {
  opacity: 0!important;
}

.arrowRemoveMobile {
  display: none!important;
}