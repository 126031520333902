import React, {
  Component,
  Fragment
} from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import Nav from '~/app/components/Nav'
import NavMobile from '~/app/components/NavMobile'
import Background from '~/app/components/Background'
import BackgroundSimple from '~/app/components/BackgroundSimple'
import Logo from '~/app/components/Logo'
import Arrow from '~/app/components/Arrow'
import LogoWord from '~/app/components/LogoWord'
import LogoBlackwing from '~/app/components/LogoBlackwing'
import LogoBlackwingT from '~/app/components/LogoBlackwingT'
import TextBox from '~/app/components/TextBox'
import MailchimpDemo from '~/app/components/MailchimpDemo'

import { isDesktop, isIe, addDocumentClasses } from '~/app/helpers/helpers'

import firstImage from '~/app/assets/img/background/1-Miles2.jpg'
import blackwingTextLogo from '~/app/assets/img/logo-blackwing-text.svg'
import lenses from '../../assets/img/lensIcons/*.svg'
import social from '~/app/assets/img/social.svg'

import { TweenMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      introAnimating: true,
      firstImageLoaded: false,
      homeMounted: false,
      navMobileIsOpen: false
    }

    this.handleNavHover = this.handleNavHover.bind(this)
    this.handleLogoClick = this.handleLogoClick.bind(this)
  }

  componentDidMount() {
    // console.log('Home mounted');

    // re-render on browser resize
    window.addEventListener('resize', this.reRender)

    // enable scrolling after the logo does its intro animation
    setTimeout(() => {
      this.setState({
        introAnimating: false,
        isHovering: false
      })
    }, 500)


    // only load the Background when the first image is loaded
    let img = document.createElement('img')
    img.onload = () => {
      // console.log('first image loaded');
      this.setState({
        firstImageLoaded: true
      })
    }
    img.src = firstImage


    const controller = new ScrollMagic.Controller()

    new ScrollMagic.Scene({triggerElement: "#trigger1"})
      .setClassToggle('#graphicSection1 > img', Cx(styles.graphicShow))
      .addTo(controller)

    new ScrollMagic.Scene({triggerElement: "#trigger2"})
      .setClassToggle('#graphicSection2 > img', Cx(styles.graphicShow))
      .addTo(controller)

    new ScrollMagic.Scene({triggerElement: "#trigger3"})
      .setClassToggle('#graphicSection3 > img', Cx(styles.graphicShow))
      .addTo(controller)

    new ScrollMagic.Scene({triggerElement: "#trigger7"})
      .setClassToggle('#graphicSection7 img', Cx(styles.graphicShow))
      .addTo(controller)

    new ScrollMagic.Scene({triggerElement: "#trigger4"})
      .setClassToggle('#graphicSection4 > img', Cx(styles.graphicShow))
      .addTo(controller)

    new ScrollMagic.Scene({triggerElement: "#trigger5"})
      .setClassToggle('#graphicSection5 > img', Cx(styles.graphicShow))
      .addTo(controller)

    setTimeout(() => {
      this.setState({ homeMounted: true })
    }, 3500)

  }

  handleNavHover(isHovering) {
    this.setState({isHovering})
  }

  handleLogoClick(status) {
    if (this.state.navMobileIsOpen) {
      this.setState({navMobileIsOpen: false})
    } else {
      // if user scrolls to the top, close the navigation (to prevent weird animation glitch on mobile)
      if (status != 'scrolled to top')
        this.setState({navMobileIsOpen: true})
    }
  }

  handleNavMobileOpenChange = (navMobileIsOpen) => {
    this.setState({navMobileIsOpen})
  }

  reRender = () => {
    // on browser resize
    this.forceUpdate()
  }

  render() {
    
    addDocumentClasses()

    // css class that hides the rest of the site until the logo animates for a couple seconds
    let classNames = Cx(
      styles.container,
      {[styles.containerIntroAnimating] : this.state.introAnimating}
    )

    return (

      <div className={classNames}>
        {/* TODO: figure out how to lift state to Root */}
        { this.state.firstImageLoaded &&
        <Fragment>
          { this.state.homeMounted && isDesktop() &&
            <Fragment>
              <Nav onNavHover={this.handleNavHover}/>
              <LogoBlackwingT/>
            </Fragment>
          }
          { !isDesktop() &&
            <NavMobile navMobileIsOpen={this.state.navMobileIsOpen} onNavMobileOpenChange={this.handleNavMobileOpenChange} />
          }
        </Fragment>
        }

        { this.state.firstImageLoaded && !isIe() && // load background after the first image has loaded, to prevent glitchy slideshow
        <Background />
        }
        { this.state.firstImageLoaded && isIe() && // load background after the first image has loaded, to prevent glitchy slideshow
        <BackgroundSimple />
        }

        <div className={Cx('landing', styles.landing)}>
          { this.state.firstImageLoaded && // logo animation skips if the images are still loading
          <Fragment>
            <Logo onLogoClick={this.handleLogoClick} navMobileIsOpen={this.state.navMobileIsOpen} />
            <Arrow />
          </Fragment>
          }
        </div>



        <div className={Cx(
          styles.navHoverDiv,
          {[styles.navHovering] : this.state.isHovering},
          {[styles.navMobileOpen]: this.state.navMobileIsOpen}
        )}>

          <div className="main">

            <LogoWord/>


            <TextBox col={3} yFrom={360} yTo={-90}>
              is a company dedicated to deepening the possibilities <br/>
              of <b>contemporary image creation</b>.
            </TextBox>
            <TextBox col={1} yFrom={140} yTo={-60} isPin={true}>
              Tribe<sup>7</sup> was founded as a collaboration <br/>
              between artist and Academy Award®-nominated  <br/>
              cinematographer <b>Bradford Young</b>, ASC,
            </TextBox>
            <TextBox col={4} yFrom={70} yTo={-30}>
              and <b>Neil Fanthom</b>, motion picture camera and lens&nbsp;technologist
            </TextBox>

            <div id="graphicSection1" className={Cx(styles.graphicSection)}>
              <div id="trigger1" className={Cx(styles.trigger)} />
              <img className={Cx(styles.graphic)} src={lenses['lens1']} />
            </div>

            <TextBox col={1} yFrom={140} yTo={-60} isPin={true} keepLineBreaksOnMobile={true}>
              our primary areas of focus are <br/>
              <b>color</b>, <b>light</b>, and <b>optics</b>.
            </TextBox>
            <TextBox col={5} yFrom={70} yTo={-30}>
              we explore how these foundational aspects of imaging <br/>
              can be reimagined for <b>today’s filmmakers</b>.
            </TextBox>

            <div id="graphicSection2" className={Cx(styles.graphicSection)}>
              <div id="trigger2" className={Cx(styles.trigger)} />
              <img className={Cx(styles.graphic)} src={lenses['lens1']} />
              <img className={Cx(styles.graphic)} src={lenses['lens2']} />
            </div>

            <TextBox col={7} yFrom={360} yTo={-90} isPin={true}>
              we aim to provide artists the ability <br/>
              to <b>tune</b> (enhance) and <b>de-tune</b> (distort) <br/>
              elements of color and <b>light</b>
            </TextBox>
            <TextBox col={2} yFrom={140} yTo={-60}>
              to bring a greater <b>expressive</b> range, intentionality <br/>
              and unique <b>visual</b> authorship to their story.
            </TextBox>



            <div id="graphicSection3" className={Cx(styles.graphicSection, 'navPin')}>
              <div id="trigger3" className={Cx(styles.trigger)} />
              <img className={Cx(styles.graphic)} src={lenses['lens1']} />
              <img className={Cx(styles.graphic)} src={lenses['lens2']} />
              <img className={Cx(styles.graphic)} src={lenses['lens3']} />
            </div>

            <div style={{textAlign: 'center'}}>
              <a className={styles.logoLink} href="https://www.blackwing7.com">
                <LogoBlackwing/>
              </a>
            </div>

            <div id="graphicSection7" className={Cx(styles.graphicSection)}>
              <div id="trigger7" className={Cx(styles.trigger)} />
              <a className={styles.logoLink} href="https://www.blackwing7.com">
                <img className={Cx(styles.blackwingTextLogo)} src={blackwingTextLogo} />
              </a>
            </div>

            <TextBox col={1} yFrom={300} yTo={-90} keepLineBreaksOnMobile={true}>
              <b>BLACKWING<sup>7</sup> imaging technology</b> <br/>
              <i>will come in analogue and digital forms.</i>
            </TextBox>
            <TextBox col={5} yFrom={140} yTo={-60}>
              <i>We are partnering with artists and technologists to develop new creative</i> <br/>
              <b>instruments, tools, approaches and services</b>
            </TextBox>
            <TextBox col={3} yFrom={70} yTo={-30}>
              <i>across the spectrum of contemporary</i> <b>cinematic image making</b> <br/>
              <i>to</i> <b>expand</b> <i>expressive capabilities and</i> <b>enable</b> <i>possible voices.</i>
            </TextBox>

            <div id="graphicSection4" className={Cx(styles.graphicSection, "navPin")}>
              <div id="trigger4" className={Cx(styles.trigger)} />
              <img className={Cx(styles.graphic)} src={lenses['lens1']} />
              <img className={Cx(styles.graphic)} src={lenses['lens2']} />
              <img className={Cx(styles.graphic)} src={lenses['lens3']} />
              <img className={Cx(styles.graphic)} src={lenses['lens4']} />
            </div>

            <TextBox col={1} yFrom={300} yTo={-90} keepLineBreaksOnMobile={true}>
              <b>BLACKWING<sup>7</sup> LENSES</b><br/>
              <i>are crafted through a process of innovation and intention.</i>
            </TextBox>
            <TextBox col={5} yFrom={140} yTo={-60}>
              <i>Lenses exhibit unique imaging properties which arise from the introduction of</i> <br/>
              <b>DISTORTION AND MODULARITY</b> <i>during development and manufacturing.</i>
            </TextBox>
            <TextBox col={2} yFrom={70} yTo={-30}>
              <i>Each lens has</i> <b>TUNABLE OPTICAL QUALITIES</b> <br/>
              <i>allowing properties such as sharpness, roll-off, field curvature and halation to be personalized to suit the character and intent of the owner.</i>
            </TextBox>
            <TextBox col={4} yFrom={140} yTo={-60}>
              <i>The ability to individually tune each</i> <b>BLACKWING<sup>7</sup></b> <i>lens parallels the application of EQ in music production to set</i> <b>RANGE AND SHAPE OF TONALITY</b> <i>across the audio frequency spectrum.</i>
            </TextBox>
            <div style={{marginTop: '120px'}} />
            <TextBox col={1} yFrom={70} yTo={-30}>
              <i>Due to the tuneable nature of</i> <b>BLACKWING<sup>7</sup></b> <i>lenses, availability is strictly through advanced order placement.</i>
            </TextBox>
            <div style={{marginTop: '190px'}} />
            <div className={Cx(styles.contact, 'navPin')}>
              <div>
                <a href="https://www.blackwing7.com">EXPLORE BLACKWING7.COM</a>
              </div>
            </div>


            <div className={Cx(styles.signupSection, 'navPin')} data-navtitle="Sign up">
              <MailchimpDemo/>
            </div>

            <div id="graphicSection5" className={Cx(styles.graphicSection)} style={{ margin: "0 0 calc(50vh - 100px - 35px)" }}>
              <div id="trigger5" className={Cx(styles.trigger)} />
              <img className={Cx(styles.graphic)} src={lenses['lens1']} />
              <img className={Cx(styles.graphic)} src={lenses['lens2']} />
              <img className={Cx(styles.graphic)} src={lenses['lens3']} />
              <img className={Cx(styles.graphic)} src={lenses['lens4']} />
              <img className={Cx(styles.graphic)} src={lenses['lens5']} />
            </div>

            <div className={Cx(styles.social)}>
              <a href="https://www.instagram.com/7isatribe" target="_blank" className="navPin" data-navtitle="Social">
                Instagram<sup>7</sup>
                <img src={social} />
              </a>
            </div>

            <div className={Cx(styles.credit)}><a href="http://osk.co" target="_blank">SITE BY <span>OSK</span></a></div>
          </div>

        </div>

      </div>

    )
  }
}

export default Home