import React, {
  Component,
  Fragment
} from 'react'
import Cx from 'classnames'
import styles from './styles.scss'

import smoothscroll from 'smoothscroll-polyfill'


class NavMobileItem extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    this.props.navMobileIsOpen(false)
    smoothscroll.polyfill()

    const el = this.props.el
    window.scrollTo({ top: el.offsetTop - window.innerHeight/2 + el.offsetHeight/2, left: 0, behavior: 'smooth' })
  }

  render() {

    return (
      <Fragment>
        { this.props.navItemTitle != 'Social' &&
      <div
        className={Cx(styles.item)}
        onClick={this.handleClick}
        dangerouslySetInnerHTML={{__html: this.props.navItemTitle}}
      />
        }
        { this.props.navItemTitle == 'Social' &&
      <div className={Cx(styles.linkWrap)}>
        <a className={Cx(styles.link)} href="https://www.instagram.com/7isatribe" target="_blank">Instagram<sup>7</sup></a>
      </div>
        }
      </Fragment>
    )
  }
}


export default NavMobileItem